import React from "react";

import NavBar from "./components/NavBar";
import { useAuth0 } from "./react-auth0-spa";

import { Router, Route, Switch } from "react-router-dom";
import Profile from "./components/Profile";
import Reviews from "./components/Reviews";
import history from "./utils/history";

import PrivateRoute from "./components/PrivateRoute";
import "./App.css";
import { FrontPage } from "./components/FrontPage";
import { UploadAssignments } from "./components/Admin/UploadAssignments";

function App() {
  const { loading } = useAuth0();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="app">
      <Router history={history}>
        <header>
          <NavBar />
        </header>
        <Switch>
          <Route path="/" exact component={FrontPage} />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute path="/reviews" component={Reviews} />
          <PrivateRoute path="/admin/upload" component={UploadAssignments} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;

import React from "react";
import { useAuth0 } from "../react-auth0-spa";
import { Link } from "react-router-dom";

const NavBar = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <div>
      {!isAuthenticated && (
        <button onClick={() => loginWithRedirect()}>Log in</button>
      )}

      {isAuthenticated && (
        <span>
          <Link to="/">Home</Link> | <Link to="/profile">Profile</Link> |
          <Link to="/admin/upload">Upload CSV</Link> |
          <a onClick={() => logout({ returnTo: window.origin })} href="/">
            Log out
          </a>{" "}
        </span>
      )}
    </div>
  );
};

export default NavBar;

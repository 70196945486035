import React, { useState } from "react";
import { useAuth0 } from "../react-auth0-spa";
import "./Reviews.css";

const ExternalApi = () => {
  const [showResult, setShowResult] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const [loadingApiMessage, setLoadingApiMessage] = useState(false);
  const { getTokenSilently } = useAuth0();

  const callApi = async () => {
    try {
      setLoadingApiMessage(true);
      const token = await getTokenSilently();

      const response = await fetch(
        "https://motivero-api.herokuapp.com/app/service",
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const responseData = await response.json();

      setShowResult(true);
      setApiMessage(responseData);
      setLoadingApiMessage(false);
    } catch (error) {
      console.error(error);
    }
  };

  if (!apiMessage && !loadingApiMessage) {
    callApi();
  }

  return (
    <>
      <h1>Service</h1>

      {showResult && apiMessage.reviewList && (
        <div>
          <h2>Reviews</h2>

          <div className="reviewList">
            {apiMessage.reviewList.map(review => (
              <div>
                <div>Date: {review.date}</div>
                <div>Source: {review.source}</div>
                <div>Score: {review.score}</div>
                <div>Comment: {review.comment}</div>
              </div>
            ))}
          </div>

          <h2>Debug data:</h2>
          <code>{JSON.stringify(apiMessage, null, 2)}</code>
        </div>
      )}

      {!showResult && <strong>Loading data...</strong>}
    </>
  );
};

export default ExternalApi;

import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useAuth0 } from "../../react-auth0-spa";

export const UploadAssignments = () => {
  const { getTokenSilently } = useAuth0();
  const [apiMessage, setApiMessage] = useState("");

  const onDrop = useCallback(async acceptedFiles => {
    console.log(acceptedFiles);
    const formData = new FormData();
    formData.append("file", acceptedFiles[0]);
    const token = await getTokenSilently();
    const result = await fetch(
      "https://motivero-api.herokuapp.com/assignments/upload-csv",
      {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    setApiMessage(await result.json());
    console.log("Upload result:", result);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false
  });

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop csv file here</p>
        ) : (
          <p>Drag 'n' drop csv file here, or click to select the file</p>
        )}
      </div>
      <p>
        Use download csv with this{" "}
        <a
          href="https://docs.google.com/spreadsheets/d/1U3LoKcIlYHhc5lqe5RAvP_jqWtzv0Ya2eBhAXUPHz5E/edit?usp=sharing"
          target="_blank"
        >
          google sheet
        </a>
      </p>
      {apiMessage && (
        <div>
          <h2>Server respons</h2>
          <code>{JSON.stringify(apiMessage)}</code>
        </div>
      )}
    </>
  );
};

import React from "react";
import { useAuth0 } from "../react-auth0-spa";
import { Link } from "react-router-dom";

export const FrontPage = () => {
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return (
      <div className="cards">
        <Link to="/reviews">
          <h1>Service -></h1>
        </Link>
      </div>
    );
  }

  return (
    <div>
      <h1>Welcome to Motivero</h1>
      Please use the login button to get started.
    </div>
  );
};
